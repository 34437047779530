<template>
  <b-sidebar
    id="add-new-customer-sidebar"
    :visible="isAddNewCustomerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-customer-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.customers.createNewCustomer") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewcustomer())"
          @reset.prevent="resetForm"
        >
          <!-- ID -->
          <validation-provider
            #default="validationContext"
            name="número de identificação"
            rules="required"
          >
            <b-form-group
              label-for="id"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.registerOrganization.CNPJ") }}
              </template>
              <b-form-input
                id="id"
                v-model="customerData.identificationNumber"
                :state="getValidationState(validationContext)"
                v-mask="'##.###.###/####-##'"
              />
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="nome"
            rules="required"
          >
            <b-form-group
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="name"
                v-model="customerData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- ZipCode -->
          <validation-provider
            #default="validationContext"
            name="cep"
            rules="required"
          >
            <b-form-group
              label-for="zipCode"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.register.zipCode") }}
              </template>
              <b-form-input
                id="zipCode"
                v-model="customerData.zipCode"
                :state="getValidationState(validationContext)"
                v-mask="'#####-###'"
              />
            </b-form-group>
          </validation-provider>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="endereço"
            rules="required"
          >
            <b-form-group
              label-for="address"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.register.address") }}
              </template>
              <b-form-input
                id="address"
                v-model="customerData.address"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- District -->
          <validation-provider
            #default="validationContext"
            name="bairro"
            rules="required"
          >
            <b-form-group
              label-for="district"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.register.district") }}
              </template>
              <b-form-input
                id="district"
                v-model="customerData.district"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <b-row>
            <!-- City -->
            <b-col md="9">
              <validation-provider
                #default="validationContext"
                name="cidade"
                rules="required"
              >
                <b-form-group
                  label-for="city"
                  :state="getValidationState(validationContext)"
                >
                  <template v-slot:label>
                    {{ $t("message.register.city") }}
                  </template>
                  <b-form-input
                    id="city"
                    v-model="customerData.city"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- UF -->
            <b-col md="3">
              <validation-provider
                #default="validationContext"
                name="uf"
                rules="required"
              >
                <b-form-group
                  label-for="uf"
                  :state="getValidationState(validationContext)"
                >
                  <template v-slot:label>
                    {{ $t("message.register.uf") }}
                  </template>
                  <b-form-input
                    id="uf"
                    v-model="customerData.uf"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Phones -->
          <validation-provider
            #default="validationContext"
            name="telefone"
            rules="required"
          >
            <b-form-group
              label-for="phone"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.register.phone") }}
              </template>
              <b-form-input
                id="phone"
                v-model="phoneNumbers"
                :state="getValidationState(validationContext)"
              />

              <small class="form-text text-muted">
                {{ $t("message.instructionMultipleInformation") }}
              </small>
            </b-form-group>
          </validation-provider>

          <!-- Emails -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required"
          >
            <b-form-group
              label-for="email"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.register.email") }}
              </template>
              <b-form-input
                id="email"
                v-model="emailAdresses"
                :state="getValidationState(validationContext)"
              />

              <small class="form-text text-muted">{{
                $t("message.instructionMultipleInformation")
              }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Contacts -->
          <validation-provider
            #default="validationContext"
            name="contatos"
            rules="required"
          >
            <b-form-group
              label-for="contacts"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.register.contacts") }}
              </template>

              <b-form-input
                id="contacts"
                v-model="contacts"
                :state="getValidationState(validationContext)"
                input-id="contacts"
              >
              </b-form-input>
              <small class="form-text text-muted">{{
                $t("message.instructionMultipleInformationContact")
              }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewCustomerSidebarActive",
    event: "update:is-add-new-customer-sidebar-active",
  },
  props: {
    isAddNewCustomerSidebarActive: {
      type: Boolean,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      phoneNumbers: "",
      emailAdresses: "",
      contacts: "",

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      disabledButton: false,
    };
  },

  methods: {
    submitNewcustomer() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          var phoneNumbers = this.phoneNumbers.replace(/\s+/g, "");
          var emailAdresses = this.emailAdresses.replace(/\s+/g, "");
          var contacts = this.contacts.replace(/\s+/g, "");

          this.customerData.phoneNumbers = phoneNumbers.split(",");
          this.customerData.emailAdresses = emailAdresses.split(",");
          this.customerData.contacts = contacts.split(",");
          axios({
            method: "post",
            url: `${URL_API}customer`,
            headers: getHeader(this.userData),
            data: this.customerData,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `O cliente ${this.customerData.name} foi criado com sucesso`,
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListCustomer", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },
  },

  setup(props, { emit }) {
    const blankcustomerData = {};

    const customerData = ref(JSON.parse(JSON.stringify(blankcustomerData)));
    const resetcustomerData = () => {
      customerData.value = JSON.parse(JSON.stringify(blankcustomerData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-customer-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetcustomerData);

    return {
      customerData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-customer-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
