<template>
  <div>
    <customer-list-add-new
      :is-add-new-customer-sidebar-active.sync="isAddNewCustomerSidebarActive"
      :user-options="userOptions"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              v-bind:class="{
                'align-items-center justify-content-end': isActive,
                'd-flex align-items-center justify-content-end': !isActive,
              }"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="searchQuery"
                  @keyup.enter="searchWord()"
                  :placeholder="$t('message.bookmark.search')"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    @click="searchWord()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <br v-if="isActive" />
              <b-dropdown
                right
                variant="gradient-warning"
                class="mr-1"
                v-if="restrictions('button_export_data')"
              >
                <template slot="button-content">
                  <feather-icon icon="DownloadIcon" />
                </template>
                <b-dropdown-item
                  ><download-excel
                    :data="customerList"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.dataInView')"
                  >
                    {{ $t("message.buttons.dataInView") }}
                  </download-excel></b-dropdown-item
                >
                <b-dropdown-item
                  ><download-excel
                    :fetch="allData"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.allData')"
                  >
                    {{ $t("message.buttons.allData") }}
                  </download-excel></b-dropdown-item
                >
              </b-dropdown>

              <b-button
                variant="primary"
                @click="isAddNewCustomerSidebarActive = true"
                v-if="restrictions('button_new_customer')"
              >
                <span class="text-nowrap">{{
                  $t("message.buttons.newClient")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="my-table"
        ref="refcustomerListTable"
        class="position-relative"
        :items="customerList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isBusy"
        show-empty
        @row-clicked="openViewPage"
        hover
      >
        <template #table-busy>
          <div class="text-center my-17">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ $t("message.load") }}...</strong>
          </div>
        </template>

        <template slot="empty">
          <div class="text-center my-5">
            {{ $t("message.registerNotFound") }}
          </div>
        </template>

        <!-- Column: Id Number -->
        <template #cell(Número)="data">
          {{ data.item.identificationNumber }}
        </template>

        <!-- Column: Name -->
        <template #cell(Nome)="data">
          {{ data.item.name }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("message.pagelength") }} {{ dataMeta.from }}
              {{ $t("message.of") }} {{ dataMeta.to }} {{ $t("message.of") }}
              {{ totalRegisters }} {{ $t("register") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            v-if="showPagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="eventPage(currentPage)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import usecustomersList from "./useCustomersList";
import CustomerListAddNew from "./CustomerListAddNew.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import store from "@/store";
Vue.component("downloadExcel", JsonExcel);

export default {
  components: {
    CustomerListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      customerList: [],
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,

      //Select
      userOptions: [],

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
    };
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }

      return [
        { key: "Número", sortable: false, label: this.getColLabel2("cnpj") },
        { key: "Nome", sortable: false, label: this.getColLabel("name") },
      ];
    },
  },

  async created() {
    this.onResize();
    this.getCustomerList();
  },

  mounted() {
    this.$root.$on("newListCustomer", (newListCustomer) => {
      this.isBusy = true;
      axios
        .get(
          `${URL_API}customer/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.customerList = response.data.content;
          this.totalRegisters = response.data.totalElements;
          this.perPage = response.data.numberOfElements;
          this.currentPage = 1;
          this.isBusy = false;
        });
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    async allData() {
      const response = await axios.get(
        `${URL_API}customer/list?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
        { headers: getHeader(this.userData) }
      );
      return response.data.content;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.isActive = ListWidth(this.windowWidth);
    },

    getColLabel(colName) {
      return this.$i18n.t("message.list." + colName);
    },
    getColLabel2(colName2) {
      return this.$i18n.t("message.register." + colName2);
    },

    openViewPage(row) {
      row._showDetails = !row._showDetails;
      this.$router.push({ name: "apps-customer-view", params: { id: row.id } });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    startDownload() {
      this.isBusy = true;
    },

    finishDownload() {
      this.isBusy = false;
    },

    async getCustomerList() {
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}customer/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.totalRegisters = response.data.totalElements;
          this.customerList = response.data.content;
          this.perPage = response.data.numberOfElements;
          this.isBusy = false;
        });

      if (this.customerList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },

    async eventPage(currentPage) {
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}customer/list-dto?page=${currentPage - 1}&sort=id,${
            this.paramData.listSort
          }&size=${this.paramData.tableSize}`,
          { headers: getHeader(this.userData) }
        )
        .then((response) => {
          this.customerList = response.data.content;
          this.isBusy = false;
        });

      if (this.customerList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getCustomerFilter() {
      this.isBusy = true;
      await axios
        .get(`${URL_API}customer/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.customerList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.customerList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

    searchWord() {
      if (this.searchQuery) {
        this.getCustomerFilter();
      } else {
        this.getCustomerList();
      }
    },
  },

  setup() {
    const isAddNewCustomerSidebarActive = ref(false);

    const {
      perPage,
      currentPage,
      totalcustomers,
      dataMeta,
      searchQuery,
      refcustomerListTable,
    } = usecustomersList();

    return {
      isAddNewCustomerSidebarActive,
      perPage,
      currentPage,
      totalcustomers,
      dataMeta,
      searchQuery,
      refcustomerListTable,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
